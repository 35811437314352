import React, {Component} from "react";
import {AccountContext} from '../Admin/Account';
import {Link, Redirect} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

class ForgotPassword extends Component {

    static contextType = AccountContext;

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            code: "",
            password: "",
            confirmPassword: "",
            success: null,
            error: null,
            codeSent: false,
            redirect: false
        };
    }

    componentDidMount() {

    }

    onSendCode() {
        let _this = this;

        this.context.forgotPassword(this.state.email)
            .then((data) => {
                console.log(data);
                _this.setState({
                    success: "Please check you email for confirmation code.",
                    codeSent: true
                });
            })
            .catch(err => {
                console.error("Failed to send verification code", err);
                this.setState({error: [err.message], success: false});
                setTimeout(() => {
                    this.setState({error: null});
                }, 7000);
            });
    }

    validate() {
        let errors = [];
        if (!this.state.code) {
            errors.push("Verification Code can not be empty");
        }
        if (!this.state.password) {
            errors.push("Password can not be empty");
        }
        if (!this.state.password) {
            errors.push("Confirm Password can not be empty");
        }
        if (this.state.password !== this.state.confirmPassword) {
            errors.push("Confirm password does not match to Password");
        }
        return errors;
    }

    onConfirmCode() {
        let errors = this.validate();

        if (errors.length > 0) {
            this.setState({
                error: errors
            });
            return;
        }

        let _this = this;

        this.context.confirmPassword(this.state.email, this.state.code, this.state.password)
            .then((data) => {
                console.log(data);
                _this.setState({
                    success: "Your password has been reset. You are going to be redirected to Sign-In page.",
                    redirect: true
                });
            })
            .catch(err => {
                console.error("Failed to verify", err);
                this.setState({error: [err.message], success: false});
                setTimeout(() => {
                    this.setState({error: null});
                }, 7000);
            });
    }

    render() {
        return (
            (this.state.redirect) ?
                <Redirect to={{pathname: "/sign-in"}}/>
                :
                <form>
                    <Container className={"vh-100"}>
                        <Row className={"justify-content-md-center"}>
                            <Col>
                                {
                                    this.state.success &&
                                    <Alert variant={"success"}>
                                        {this.state.success}
                                    </Alert>
                                }
                                {
                                    this.state.error &&
                                    <Alert variant={"danger"}>
                                        {
                                            this.state.error.map(err => {
                                                return <div>{err.replace("USERNAME", "Email")}</div>
                                            })
                                        }
                                    </Alert>
                                }
                            </Col>
                        </Row>


                        <Row className={"d-flex justify-content-center"} style={{height: "80%"}}>
                            <Col lg={"5"} md={"7"} sm={"9"} xs={"11"}
                                 className={"d-flex align-items-center justify-content-center"}>

                                {
                                    (!this.state.codeSent) ?
                                        <Container>
                                            <Row className="mb-2">
                                                <Form.Group as={Col} className="mb-3" controlId="formHorizontalEmail">
                                                    <Form.Label>
                                                        Enter email address:
                                                    </Form.Label>
                                                    <Form.Control type="email" placeholder="Email" value={this.state.email}
                                                                  onChange={(event) =>
                                                                      this.setState({email: event.target.value})}/>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-2">
                                                <Col className={"d-flex align-items-center justify-content-center"} xs={6}>
                                                    <Button variant="primary" type="button" className={"w-75"}
                                                            onClick={this.onSendCode.bind(this)}>
                                                        Send
                                                    </Button>
                                                </Col>

                                                <Col className={"d-flex align-items-center justify-content-center"} xs={6}>
                                                    <Link to="/sign-in">Sign In</Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                        :
                                        <Container>

                                            <Row className="mb-2">
                                                <Form.Group as={Col} className="mb-3" controlId="formHorizontalEmail">
                                                    <Form.Label>
                                                        Verification Code:
                                                    </Form.Label>
                                                    <Form.Control placeholder="6 digit code" value={this.state.code}
                                                                  onChange={(event) => this.setState({code: event.target.value})}/>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-2">
                                                <Form.Group as={Col} className="mb-3" controlId="formHorizontalPassword">
                                                    <Form.Label>
                                                        Password:
                                                    </Form.Label>
                                                    <Form.Control type="password" placeholder="Password"
                                                                  value={this.state.password}
                                                                  onChange={(event) => this.setState({password: event.target.value})}/>
                                                </Form.Group>

                                                <Form.Group as={Col} className="mb-3" controlId="formHorizontalConfirmPassword">
                                                    <Form.Label>
                                                        Confirm Password:
                                                    </Form.Label>
                                                    <Form.Control type="password" placeholder="Confirm Password"
                                                                  value={this.state.confirmPassword}
                                                                  onChange={(event) => this.setState({confirmPassword: event.target.value})}/>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-2">
                                                <Col className={"d-flex align-items-center justify-content-center"} xs={6}>
                                                    <Button variant="primary" className={"w-75"}
                                                            onClick={this.onConfirmCode.bind(this)}>
                                                        Confirm
                                                    </Button>
                                                </Col>
                                                <Col className={"d-flex align-items-center justify-content-center"} xs={6}>
                                                    <Button variant="outline-primary" type="button" className={"w-75"}
                                                            onClick={this.onSendCode.bind(this)}>
                                                        Resend code
                                                    </Button>
                                                </Col>
                                            </Row>

                                            <Row className="mb-2">

                                                <Col className={"d-flex align-items-center justify-content-center"} xs={6}>
                                                    <Link to="/sign-in">Sign In</Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                }
                            </Col>
                        </Row>
                    </Container>
                </form>
        );
    }
}

export default ForgotPassword;