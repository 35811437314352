import React, { Component } from "react";
import "./App.css";
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";

import NavbarPage from "./components/Navbar/NavbarPage";
import Section from "./section";
import Section1 from "./components/Sections/section1";
import Section2 from "./components/Sections/section2";
import Section3 from "./components/Sections/section3";
import Section4 from "./components/Sections/section4";
import Section5 from "./components/Sections/section5";
import Section6 from "./components/Sections/section6";
import Section7 from "./components/Sections/section7";
import Section8 from "./components/Sections/section8";
import Footer from "./components/Footer/footer";

import {Account} from "./components/Admin/Account";
import SignIn from "./components/Auth/SignIn";

//  Magnific-popup
import "./assets/css/magnific-popup.css";

//  css
import "./assets/css/bootstrap.min.css";
import "./assets/css/icons.css";
import "./assets/css/style.css";
import VerifyCode from "./components/Auth/VerifyUser";
import ForgotPassword from "./components/Auth/ForgotPassword";
import SignOut from "./components/Auth/SignOut";
import SignUp from "./components/Auth/SignUp";
import Header from "./components/Admin/Header";
import SignedComponent from "./components/Admin/SignedComponent";
import Dashboard from "./components/Admin/Dashboard";

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			pos: document.documentElement.scrollTop,
			imglight: false,
			navClass: "",
			isStickyNav: true,
		};
	}

	componentDidMount() {
		window.addEventListener("scroll", this.scrollNavigation, true);
		this.scrollNavigation();
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.scrollNavigation, true);
	}

	scrollNavigation = () => {
		var scrollup = document.documentElement.scrollTop;

		if (scrollup > this.state.pos) {
			this.setState({navClass: "darkheader", imglight: false});
			this.setState({isStickyNav: false});
		} else if (window.innerWidth <= 768) {
			this.setState({navClass: "darkheader", imglight: false});
			this.setState({isStickyNav: false});
		} else {
			this.setState({navClass: "", imglight: true});
			this.setState({isStickyNav: true});
		}
	};

	render() {
		return (
			<Account>
				<Router>
					<Switch>
						<Route exact path="/">
							<React.Fragment>
								<NavbarPage
									navclass={this.state.navClass}
									imglight={this.state.imglight}
									isStickyNav={this.state.isStickyNav}
								/>

								<Section/>

								<Section1/>

								<Section2/>

								<Section3/>

								<Section4/>

								<Section5/>

								<Section6/>

								<Section7/>

								<Section8/>

								<Footer/>
							</React.Fragment>
						</Route>
						<Route exact path="/dashboard">
							<Header headerText={"Dashboard"}>
								<SignedComponent/>
								<Dashboard/>
							</Header>
						</Route>
						<Route path="/sign-up">
							<div>
								<SignUp/>
							</div>
						</Route>
						<Route path="/sign-in">
							<div>
								<SignIn/>
							</div>
						</Route>
						<Route path="/verify-code">
							<div>
								<VerifyCode/>
							</div>
						</Route>
						<Route path="/forgot">
							<div>
								<ForgotPassword/>
							</div>
						</Route>
						<Route path="/sign-out">
							<div>
								<SignOut/>
							</div>
						</Route>
					</Switch>
				</Router>
			</Account>
		);
	}
}

export default App;
