import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Images
import img1 from "../../assets/images/asset-2.gif";

class Section2 extends Component {
	render() {
		return (
			<React.Fragment>
				{/* FEATURES START  */}
				<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5" id="features">
					<Container>

						<Row className="align-items-center">

							<Col lg={6} className="order-lg-12">
								<div className="features-img">
									<img
										src={img1}
										className="img-fluid mx-auto d-block"
									/>
								</div>
							</Col>

							<Col lg={6} className="order-lg-1">
								<div className="pr-lg-6 mr-lg-5">
									<h3 className="line-height-1_6 text-dark mb-4">
										<strong>
											Provide end-to-end patient experience
										</strong>
									</h3>
									<p className="text-muted mb-4 f-15 text-justify">
										Our modular platform saves you time and automates tasks. Get a full overview of
										your patient's healthcare journey with seamless database and prescription
										interfaces. We empower you to transition from legacy software towards our secure
										web application in the cloud, while saving you money and time.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section2;
