import React, {Component} from "react";
import {
	Container,
	Navbar,
	Collapse,
	Nav,
	NavItem,
	NavLink,
	NavbarToggler,
	NavbarBrand,
} from "reactstrap";

import { faUser, faFlag } from "@fortawesome/free-regular-svg-icons";
import { faCubes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import StickyHeader from "react-sticky-header";
import NavDropdown from 'react-bootstrap/NavDropdown'

import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import {Redirect} from "react-router-dom";

class NavbarPage extends Component {
	prevScrollpos = 0;

	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			topPos: "0",
			isOpenMenu: false,
			navCenterClass: "",
			clickedSignUp: false
		};

		this.toggleLine = this.toggleLine.bind(this);

		this.handleScrollMenu = this.handleScrollMenu.bind(this);
	}

	handleScrollMenu = async () => {
		this.prevScrollpos = window.pageYOffset;

		if (window.innerWidth <= 768) {
			await this.setState({navCenterClass: ""});
		} else {
			await this.setState({
				navCenterClass: "navbar-nav mx-auto navbar-center",
			});
		}
	};

	componentDidMount() {
		this.prevScrollpos = window.pageYOffset;
		window.addEventListener("scroll", this.handleScrollMenu);
		if (window.innerWidth <= 768) {
			this.setState({navCenterClass: ""});
		} else {
			this.setState({navCenterClass: "navbar-nav mx-auto navbar-center"});
		}
	}

	toggleLine() {
		this.setState((prevState) => ({isOpen: !prevState.isOpen}));
	}

	toggle = async () => {
		await this.setState({isOpenMenu: !this.state.isOpenMenu});
	};

	onSignUp() {
		this.setState({clickedSignUp: true})
	}

	render() {
		return (
			(this.state.clickedSignUp) ?
				<Redirect to={{pathname: "/sign-up"}}/> :
				<React.Fragment>
					<StickyHeader
						header={
							<Navbar
								className={`navbar navbar-expand-xl fixed-top navbar-custom ${
									this.props.isStickyNav === true
										? `sticky sticky-light`
										: `navbar-light`
								}`}
								id="navbar"
								style={{top: this.state.topPos}}
							>
								<Container>
									<NavbarBrand href="/" className="logo">
										<img
											src={this.props.imglight ? logolight : logodark}
											alt=""
											className="logo-light"
											height={64}
										/>
									</NavbarBrand>

									<NavbarToggler
										className="navbar-toggler"
										type="button"
										aria-label="Toggle navigation"
										onClick={this.toggle}
									>
										<i className="remixicon-menu-fill"/>
									</NavbarToggler>
									<Collapse
										id="navbarCollapse"
										isOpen={this.state.isOpenMenu}
										navbar
									>
										<Nav className="navbar-nav navbar-center">
											<NavItem className={"nav-item"}>
												<NavLink href={"https://www.healthics.com"} className={"nav-link"}>
													Personal
												</NavLink>
											</NavItem>
											<NavItem className={"nav-item"}>
												<NavDropdown title="BUSINESS" id="nav-dropdown">
													<NavDropdown.Item href={"https://cloud.healthics.com"}
																	  className={"text-black"}
																	  style={{color: "#000000"}}>
														Healthics Cloud
													</NavDropdown.Item>
													<NavDropdown.Item href={"https://api.healthics.com"}
																	  className={"text-black"}>
														Healthics API
													</NavDropdown.Item>
												</NavDropdown>
											</NavItem>
											<NavItem className={"nav-item"}>
												<NavLink href={"https://gov.healthics.com"} className={"nav-link"}>
													Government
												</NavLink>
											</NavItem>
										</Nav>

										<Nav className="navbar-nav ml-auto">
											<NavItem className={"nav-item"}>
												<NavLink href={"#"} className={"nav-link mt-2"}>
													<FontAwesomeIcon icon={faFlag}
																	 style={{color: '#9C9C9C', fontSize: "150%"}}
																	 fixedWidth/> Estonia
												</NavLink>
											</NavItem>
											<NavItem className={"nav-item"}>
												<NavLink href={"#"} className={"nav-link mt-2"}>
													<FontAwesomeIcon icon={faCubes}
																	 style={{color: '#9C9C9C', fontSize: "150%"}}
																	 fixedWidth/> Service
												</NavLink>
											</NavItem>
											<NavItem className={"nav-item"}>
												<NavLink href={"/sign-in"} className={"nav-link mt-2"}>
													<FontAwesomeIcon icon={faUser}
																	 style={{color: '#9C9C9C', fontSize: "150%"}}
																	 fixedWidth/> Login
												</NavLink>
											</NavItem>
											<NavItem className={"nav-item"}>
												<button
													type="button"
													onClick={this.onSignUp.bind(this)}
													className="land land-btn rounded-pill ml-1 text-white"
													style={{backgroundColor: "#1F87F6", border: "none"}}>
													Sign Up
												</button>
											</NavItem>
										</Nav>
									</Collapse>
								</Container>
							</Navbar>
						}
						stickyOffset={-100}
					/>
				</React.Fragment>
		);
	}
}

export default NavbarPage;
