import React, {Component} from "react";
import {AccountContext} from "./Account";
import {
    Container
} from "react-bootstrap";

import Steps from "./Steps";

class Dashboard extends Component {

    static contextType = AccountContext;

    constructor(props) {
        super(props);

        this.state = {showModal: false};
    }

    render() {
        return (
            <Steps/>
        );
    }
}

export default Dashboard;