import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {AccountContext} from "./Account";

class Steps extends Component {

    static contextType = AccountContext;

    static OTHER = "2";
    static BUSINESS = "business";
    static INDIVIDUAL = "individual";

    constructor(props) {
        super(props);

        this.session = null;

        this.state = {
            typeOfBusiness: Steps.BUSINESS,
            country: "0",
            field: "0",
            fieldCustom: "",
            disableNext: true,
            companyName: "",
            firstName: "",
            lastName: "",
            comment: "",
            locked: true,
            success: "",
            error: ""
        }
    }

    componentDidMount() {

        this.context.getSession().then((session) => {
            this.session = session;

            fetch(`https://zg3lkmwq42.execute-api.eu-central-1.amazonaws.com/DEV/client/${this.session.user.username}/info`,
                {
                    method: "GET",
                    headers: {
                        'Accept': 'application/json',
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + this.session.session.idToken.jwtToken,
                        'Origin': ''
                    }
                })
                .then(res => res.json())
                .then(res => {
                    if (res["Item"]["onboarding"]) {
                        this.setState({
                            typeOfBusiness: res["Item"]["onboarding"]["typeOfBusiness"],
                            country: res["Item"]["onboarding"]["country"],
                            field: res["Item"]["onboarding"]["field"],
                            fieldCustom: res["Item"]["onboarding"]["fieldCustom"],
                            disableNext: res["Item"]["onboarding"]["disableNext"],
                            companyName:res["Item"]["onboarding"]["companyName"],
                            firstName: res["Item"]["onboarding"]["firstName"],
                            lastName: res["Item"]["onboarding"]["lastName"],
                            comment: res["Item"]["onboarding"]["comment"],
                            success: "Your application is waiting to be approved by Healthics administrator",
                            locked: true
                        });
                    } else {
                        this.setState({
                            locked: false
                        });
                    }
                    console.log(res["Item"]["onboarding"]);
                })
                .catch((err) => {
                    console.error(err);
                    // this.setState({redirect: true, loading: false});
                });
            // this.setState({locked: false});
        });
    }

    onUnlock() {
        this.setState({locked: false});
    }

    onSubmit(event) {
        event.preventDefault();

        this.context.getSession().then((result) => {
            console.log(result);

            fetch(`https://zg3lkmwq42.execute-api.eu-central-1.amazonaws.com/DEV/client/${result.user.username}/submit-for-approval`,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + result.session.idToken.jwtToken,
                        'Origin': ''
                    },
                    body: JSON.stringify({
                        typeOfBusiness: this.state.typeOfBusiness,
                        country: this.state.country,
                        field: this.state.field,
                        fieldCustom: this.state.field === Steps.OTHER ? this.state.fieldCustom: "",
                        companyName: this.state.typeOfBusiness === Steps.BUSINESS ? this.state.companyName: "",
                        firstName: this.state.typeOfBusiness === Steps.INDIVIDUAL ? this.state.firstName: "",
                        lastName: this.state.typeOfBusiness === Steps.INDIVIDUAL ? this.state.lastName: "",
                        comment: this.state.comment
                    })
                })
                .then(res => res.json())
                .then(res => {
                    this.setState({
                        locked: true,
                        success: "Your account has been submitted for approval."
                    });
                })
                .catch((err) => {
                    console.error(err);
                    // this.setState({redirect: true, loading: false});
                });
        });
        this.setState({locked: true});
    }

    render() {
        return (
            <form onSubmit={this.onSubmit.bind(this)}>
                <Container>
                    <Row className={"justify-content-md-center"}>
                        <Col>
                            {
                                this.state.error &&
                                <Alert variant={"danger"}>
                                    {
                                        this.state.error.map(err => {
                                            return <div>{err}</div>
                                        })
                                    }
                                </Alert>
                            }
                            {
                                this.state.success &&
                                <Alert variant={"success"}>
                                    {this.state.success}
                                </Alert>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>Onboarding process</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"d-flex justify-content-center"}>

                            <Container>

                                <Row className="mb-2">
                                    <Form.Group as={Col} className="mb-3" sm={12} md={2}>
                                        <Form.Label>
                                            Type of business:
                                        </Form.Label>
                                        <div>
                                            <Form.Check id={"business-radio-id"} inline label="Business"
                                                        name="typeOfBusiness" type={'radio'}
                                                        value={"business"}
                                                        checked={this.state.typeOfBusiness === Steps.BUSINESS}
                                                        disabled={this.state.locked}
                                                        onChange={(event) => this.setState({
                                                            typeOfBusiness: event.target.value,
                                                            field: "0"
                                                        })}/>
                                        </div>
                                        <div>
                                            <Form.Check id={"individual-radio-id"} inline label="Individual"
                                                        name="typeOfBusiness" type={'radio'}
                                                        value={"individual"}
                                                        checked={this.state.typeOfBusiness === Steps.INDIVIDUAL}
                                                        disabled={this.state.locked}
                                                        onChange={(event) => this.setState({
                                                            typeOfBusiness: event.target.value,
                                                            field: "0"
                                                        })}/>
                                        </div>
                                    </Form.Group>

                                    <Form.Group as={Col} className="mb-3" controlId="formHorizontalEmail" sm={12}
                                                md={5}>
                                        <Form.Label>
                                            Country:
                                        </Form.Label>
                                        <Form.Control as="select"
                                                      value={this.state.country}
                                                      disabled={this.state.locked}
                                                      onChange={(event) => {
                                                          this.setState({country: event.target.value});
                                                      }}>
                                            <option value={0}>Choose...</option>
                                            <option value={"Austria"}>Austria</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Republicof Cyprus">Republicof Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sweden">Sweden</option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group as={Col} className="mb-3" controlId="formHorizontalEmail" sm={12}
                                                md={5}>
                                        <Form.Label>
                                            Field:
                                        </Form.Label>
                                        {
                                            (this.state.typeOfBusiness === Steps.INDIVIDUAL) ?
                                                <Form.Control as="select"
                                                              value={this.state.field}
                                                              disabled={this.state.locked}
                                                              onChange={(event) => {
                                                                  this.setState({field: event.target.value});
                                                              }}>
                                                    <option value={0}>Choose...</option>
                                                    <option value={1}>Practitioner</option>
                                                    <option value={2}>Other</option>
                                                </Form.Control> :
                                                <Form.Control as="select"
                                                              value={this.state.field}
                                                              disabled={this.state.locked}
                                                              onChange={(event) => this.setState({field: event.target.value})}>
                                                    <option value={0}>Choose...</option>
                                                    <option value={1}>Hospital</option>
                                                    <option value={2}>Other</option>
                                                </Form.Control>
                                        }
                                    </Form.Group>

                                    {(this.state.field === Steps.OTHER) ?
                                        <Form.Group as={Col} className="mb-3" controlId="formHorizontalEmail"
                                                    sm={{span: 12, offset: 0}}
                                                    md={{span: 5, offset: 7}}>
                                            <Form.Label>
                                                Other Field:
                                            </Form.Label>
                                            <Form.Control placeholder="Enter other field"
                                                          value={this.state.fieldCustom}
                                                          disabled={this.state.locked}
                                                          onChange={(event) => this.setState({fieldCustom: event.target.value})}/>
                                        </Form.Group> : <></>
                                    }
                                </Row>

                                {
                                    (this.state.typeOfBusiness === Steps.INDIVIDUAL) ?

                                        <Row className="mb-2">
                                            <Form.Group as={Col} className="mb-3" sm={12} md={6}>
                                                <Form.Label>
                                                    First Name:
                                                </Form.Label>
                                                <Form.Control placeholder="Enter first name"
                                                              value={this.state.firstName}
                                                              disabled={this.state.locked}
                                                              onChange={(event) => this.setState({firstName: event.target.value})}/>
                                            </Form.Group>
                                            <Form.Group as={Col} className="mb-3" sm={12} md={6}>
                                                <Form.Label>
                                                    Last Name:
                                                </Form.Label>
                                                <Form.Control placeholder="Enter last name"
                                                              value={this.state.lastName}
                                                              disabled={this.state.locked}
                                                              onChange={(event) => this.setState({lastName: event.target.value})}/>
                                            </Form.Group>
                                        </Row>
                                        :
                                        <Row className="mb-2">
                                            <Form.Group as={Col} className="mb-3" sm={12} md={6}>
                                                <Form.Label>
                                                    Company Name:
                                                </Form.Label>
                                                <Form.Control placeholder="Enter company name"
                                                              value={this.state.companyName}
                                                              disabled={this.state.locked}
                                                              onChange={(event) => this.setState({companyName: event.target.value})}/>
                                            </Form.Group>
                                        </Row>
                                }
                                <Row className="mb-2">
                                    <Form.Group as={Col} className="mb-3" sm={12} md={6}>
                                        <Form.Label>
                                            Comment:
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Leave a comment here"
                                            value={this.state.comment}
                                            disabled={this.state.locked}
                                            onChange={(event) => this.setState({comment: event.target.value})}
                                            style={{ height: '100px' }}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Col className={"d-flex align-items-center justify-content-end"} xs={6}>
                                        {
                                            (this.state.locked) ?
                                                <Button variant="secondary" type="button" onClick={this.onUnlock.bind(this)}>
                                                    Unlock
                                                </Button>
                                                : <></>
                                        }
                                    </Col>

                                    <Col className={"d-flex align-items-center justify-content-begin"} xs={6}>
                                        <Button variant="primary" type="submit"
                                                disabled={this.state.locked || this.state.country === "0" || this.state.field === "0" ||
                                                (this.state.field === Steps.OTHER && this.state.fieldCustom.length === 0) ||
                                                (this.state.typeOfBusiness === Steps.INDIVIDUAL && (this.state.firstName === "" || this.state.lastName === "")) ||
                                                (this.state.typeOfBusiness === Steps.BUSINESS && this.state.companyName === "" )}>
                                            Send for approval
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </form>
        )
    }
}

export default Steps;