import React, { useContext } from "react";
import { AccountContext } from '../Admin/Account';
import {Redirect} from "react-router-dom";

const SignOut = () => {

    const {signOut} = useContext(AccountContext);

    signOut();

    return (
        <Redirect to={{pathname: "/"}}/>
    );
};

export default SignOut;