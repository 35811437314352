import React, {Component} from "react";
import { AccountContext } from "./Account";
import { Redirect } from "react-router-dom";

class SignedComponent extends Component {

    static contextType = AccountContext;

    constructor(props) {
        super(props);
        this.state = {loading: true, logged: false};
    }

    componentDidMount() {
        this.context.getSession().then((result) => {
            if (result.session) {
                this.setState({
                    logged: true,
                    loading: false
                });
            } else {
                this.setState({
                    logged: false,
                    loading: false
                });
            }
        }).catch(err => {
            console.log(err);
            this.setState({
                logged: false,
                loading: false
            });
        });
    }

    render() {
        return (
            (!this.state.loading && !this.state.logged) ?
                <Redirect to={{pathname: "/sign-in"}}/> :
                <></>
        )

    }
}

export default SignedComponent;