import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Images
import img1 from "../../assets/images/asset-4.png";

class Section5 extends Component {
	render() {
		return (
			<React.Fragment>
				{/* FEATURES START  */}
				<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5" id="features">
					<Container>

						<Row className="align-items-center">

							<Col lg={6} className="order-lg-12">
								<div className="features-img">
									<img
										src={img1}
										className="img-fluid mx-auto d-block"
									/>
								</div>
							</Col>

							<Col lg={6} className="order-lg-1">
								<div className="pr-lg-6 mr-lg-5">
									<h3 className="line-height-1_6 text-dark mb-4">
										<strong>
											Healthcare Communication made for everyone
										</strong>
									</h3>
									<p className="text-muted mb-4 f-15 text-justify">
										You can be confident that your treatment instructions will reach your patients
										in the right way. Our modular platform allows you to provide products & services
										via text, video, voice and other. Simply select your preferred way of
										communication or upload your own.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section5;
