import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Images
import img1 from "../../assets/images/asset-3.png";

class Section4 extends Component {
	render() {
		return (
			<React.Fragment>
				{/* FEATURES START  */}
				<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5" id="features">
					<Container>

						<Row className="align-items-center">
							<Col lg={6}>
								<div className="features-img mt-4">
									<img
										src={img1}
										className="img-fluid mx-auto d-block"
									/>
								</div>
							</Col>

							<Col lg={6}>
								<div className="pr-lg-6 mt-4 ml-lg-5">
									<h3 className="line-height-1_6 text-dark">
										<strong>
											Take care of your clients while
											we do the rest.
										</strong>
									</h3>
									<p className="text-muted f-15 text-justify">
										Thanks to our seamless integration of healthcare data and wellness data you can
										now monitor data from a variety of sources in real time, giving you unparalleled
										quality of services.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section4;
