import React, { Component } from "react";
import {
	Container,
	Row,
	Col
} from "reactstrap";

//Slider
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//Import Client images
import img1 from "../../assets/images/carosel-1.png";
import img2 from "../../assets/images/carosel-2.png";
import img3 from "../../assets/images/carosel-3.png";

class Section3 extends Component {
	state = {
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 2,
			},
			960: {
				items: 3,
			},
			1200: {
				items: 3,
			},
		},
	};

	render() {
		return (
			<React.Fragment>
				{/* <!-- TESTIMONIAL START --> */}

				<section className="section position-relative" id="client">
					<Container>
						<div className="row">
							<OwlCarousel
								className="owl-theme client-images text-center"
								items={2}
								loop={true}
								margin={10}
								nav={false}
								dots={true}
								autoplay={true}
								responsive={this.state.responsive}
								autoplayHoverPause={true}
								autoplayTimeout={2500}
							>
								<div className="item">
									<section
										className="hero-2-bg"
										style={{background: `url(${img1}) center center`}}
									>
										<Container className={"pt-5 mt-5"}>

											<Row>
												<Col>
													<h1 className="text-white" style={{fontSize:"30px"}}>
														For wellness & fitness coaches
													</h1>
												</Col>
											</Row>

											<Row>
												<Col>
													<button
														type="button"
														className="land-btn rounded-pill land-btn-white ml-1"
														style={{color: "black"}}
													>
														Learn More
													</button>
												</Col>
											</Row>
										</Container>
									</section>
								</div>
								{/* owl item and */}
								<div className="item">
									<section
										className="hero-2-bg"
										style={{background: `url(${img2}) center center`}}
									>
										<Container className={"pt-5 mt-5"}>

											<Row>
												<Col>
													<h1 className="text-white" style={{fontSize:"30px"}}>
														For healthcare & clinical professionals
													</h1>
												</Col>
											</Row>

											<Row>
												<Col>
													<button
														type="button"
														className="land-btn rounded-pill land-btn-white ml-1"
														style={{color: "black"}}
													>
														Learn More
													</button>
												</Col>
											</Row>
										</Container>
									</section>
								</div>
								{/* owl item and */}
								<div className="item">
									<section
										className="hero-2-bg"
										style={{background: `url(${img3}) center center`}}
									>
										<Container className={"pt-5 mt-5"}>

											<Row>
												<Col>
													<h1 className="text-white" style={{fontSize:"30px"}}>
														For insurance providers
													</h1>
												</Col>
											</Row>

											<Row>
												<Col>
													<button
														type="button"
														className="land-btn rounded-pill land-btn-white ml-1"
														style={{color: "black"}}
													>
														Learn more
													</button>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

							</OwlCarousel>
						</div>
					</Container>
				</section>
				{/* TESTIMONIAL END */}
			</React.Fragment>
		);
	}
}

export default Section3;
