import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Images
import img1 from "../../assets/images/service-1.png";
import img2 from "../../assets/images/service-2.png";
import img3 from "../../assets/images/service-3.png";

class Section8 extends Component {
	render() {
		return (
			<React.Fragment>
				{/* FEATURES START  */}
				<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5" id="features">
					<Container>

						<Row>
							<Col lg={4}>
								<div className="border border-right">
									<div className="features-img">
										<img
											src={img1}
											className="img-fluid mx-auto d-block"
										/>
									</div>
									<div className={"m-3"}>
										<h4>
											The healthcare journey as
											a process
										</h4>
									</div>
									<div className={"mt-4 mb-5 mx-3"}>
										The healthcare budget of EU 27 is 1.5 trillion
										euro. Digital healthcare can reduce it by 50%
										and make delivery 60% fasterDigital
										healthcare can reduce it by
									</div>
								</div>
							</Col>
							<Col lg={4}>
								<div className="border border-right">
									<div className="features-img">
										<img
											src={img2}
											className="img-fluid mx-auto d-block"
										/>
									</div>
									<div className={"m-3"}>
										<h4>
											The Estonian approach to
											cyber-security
										</h4>
									</div>
									<div className={"mt-4 mb-5 mx-3"}>
										The healthcare budget of EU 27 is 1.5 trillion
										euro. Digital healthcare can reduce it by 50%
										and make delivery 60% fasterDigital
										healthcare can reduce it by
									</div>
								</div>
							</Col>
							<Col lg={4}>
								<div className="border border-right">
									<div className="features-img">
										<img
											src={img3}
											className="img-fluid mx-auto d-block"
										/>
									</div>
									<div className={"m-3"}>
										<h4>
											Sharing your health data
											accelerates R&D
										</h4>
									</div>
									<div className={"mt-4 mb-5 mx-3"}>
										The healthcare budget of EU 27 is 1.5 trillion
										euro. Digital healthcare can reduce it by 50%
										and make delivery 60% fasterDigital
										healthcare can reduce it by
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section8;
