import React, {Component} from "react";

import CounterBg from "../../assets/images/API-healthics.png";
import {Container, Row, Col} from "reactstrap";

class Section7 extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<section
					className="section position-relative"
					style={{background: `url(${CounterBg}) center center`}}
				>
					<Container className="container">
						<Row id="counter">
							<Col>
								<div className="text-white pl-4 mb-3">
									<h1>API for developers</h1>
								</div>
								<div className="text-white pl-4 mb-3 col-lg-8">
									<h3>Build and deploy healthcare products and services within days</h3>
								</div>
								<button
									type="button"
									className="land-btn rounded-pill land-btn-white ml-2"
									style={{color: "black"}}
								>
									Learn More
								</button>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section7;
