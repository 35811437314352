import React, { Component } from "react";

import { Container, Row, Col } from "reactstrap";

//Import Image
import heroBgImg from "./assets/images/main-bg.png";
import {Redirect} from "react-router-dom";

class Section extends Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
			clickedSignUp: false
		};
		this.callModal.bind(this);
	}

	callModal = () => {
		this.refs.child.openModal();
	};

	onSignUp() {
		this.setState({clickedSignUp: true})
	}

	render() {
		return (
			(this.state.clickedSignUp) ?
				<Redirect to={{pathname: "/sign-up"}}/> :
			<React.Fragment>
				{/* HERO START */}

				<section
					className="hero-3-bg position-relative"
					style={{background: `url(${heroBgImg}) top center no-repeat`, backgroundSize:"cover", height: "100vh"}}
				>
					
					<div/>
					<Container>
						<Row className=" justify-content-center">
							<Col lg={8}>
								<div className="text-center">
									<h1 className="text-white hero-3-title mb-4 line-height-1_4">
										For efficient and scalable healthcare business
									</h1>
									<p className="text-white-50 w-75 mx-auto f-15">
										All in one platform that enables your business to provide
										on-demand medical, wellness or insurance services. Choose
										the right plan for you and start today.
									</p>
								</div>
							</Col>
						</Row>
						<Row className="justify-content-center">
							<Col md={3} xs={12} className={"mb-3"}>
								<div className="text-center Subcribe-form">
									<form action="#">
										<button
											type="button"
											onClick={this.onSignUp.bind(this)}
											className="land-btn rounded-pill land-btn-purple"
										>
											Sign Up For Free
										</button>
									</form>
								</div>
							</Col>
							<Col md={3} xs={12}>
								<div className="text-center Subcribe-form">
									<form action="#">

										<button
											type="button"
											className="land-btn rounded-pill land-btn-outline-purple"
										>
											Explore Platform
										</button>
									</form>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				{/* HERO END	*/}
			</React.Fragment>
		);
	}
}

export default Section;
