import React, {createContext} from "react";
import {CognitoUser, AuthenticationDetails, CognitoUserAttribute} from "amazon-cognito-identity-js";
import UserPool from "../../UserPool";

const AccountContext = createContext();

const Account = (props) => {

    let currentUser;
    let session;

    const signIn = async (email, password) => {
        return await new Promise((resolve, reject) => {

            const user = new CognitoUser({Username: email, Pool: UserPool});

            const authDetails = new AuthenticationDetails({Username: email, Password: password});

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    console.log("onSuccess: ", data);
                    resolve(data);
                },
                onFailure: (err) => {
                    console.log("onFailure: ", err);
                    reject(err);
                },
                newPasswordRequired: (data) => {
                    console.log("newPasswordRequired: ", data);
                    resolve(data);
                }
            });
        });
    };

    const signUp = async (email, password, firstName, lastName, phoneNumber) => {
        return await new Promise((resolve, reject) => {

            const cognitoFirstName = new CognitoUserAttribute(
                {Name: "name", Value: firstName}
            );
            const cognitoFamilyName = new CognitoUserAttribute(
                {Name: "family_name", Value: lastName}
            );
            const cognitoPhoneNumber = new CognitoUserAttribute(
                {Name: "phone_number", Value: phoneNumber}
            );

            UserPool.signUp(email, password, [cognitoFirstName, cognitoFamilyName,
                cognitoPhoneNumber], null, (err, data)=> {
                if (err) {
                    console.log(err);
                    reject(err);
                } else {
                    currentUser = data.user;
                    console.log(data);
                    resolve(data);
                }
            });

        });
    };

    const confirmRegistration = async (code) => {
        return await new Promise((resolve, reject) => {
            if (currentUser) {
                currentUser.confirmRegistration(code, true,(err, data) => {
                    if (err) {
                        console.log(err);
                        reject(err);
                    } else {
                        console.log(data);
                        resolve(data);
                    }
                });
            } else {
                reject(new Error("User does not exist"));
            }
        });
    };

    const resendConfirmationCode = async () => {
        return await new Promise((resolve, reject) => {
            if (currentUser) {
                currentUser.resendConfirmationCode((err, data) => {
                    if (err) {
                        console.log(err);
                        reject(err);
                    } else {
                        console.log(data);
                        resolve(data);
                    }
                });
            } else {
                reject(new Error("User does not exist"));
            }
        });
    };

    const forgotPassword = async (email) => {
        return await new Promise((resolve, reject) => {
            let user = new CognitoUser({
                Username: email,
                Pool: UserPool
            });

            if (user) {
                user.forgotPassword({
                    onSuccess: (data) => {
                        console.log(data);
                        resolve(data);
                    },
                    onFailure: (err) =>{
                        console.log("Error: ", err);
                        reject(err);
                    }
                });
            } else {
                reject(new Error("User does not exist"));
            }
        });
    };

    const confirmPassword = async (email, code, password) => {
        return await new Promise((resolve, reject) => {

            let user = new CognitoUser({
                Username: email,
                Pool: UserPool
            });

            if (user) {
                user.confirmPassword( code, password, {
                    onSuccess: (data) => {
                        console.log(data);
                        resolve(data);
                    },
                    onFailure: (err) =>{
                        console.log("Error: ", err);
                        reject(err);
                    }
                });
            } else {
                reject(new Error("User does not exist"));
            }
        });
    };

    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();
            if (user) {
                if (!session) {
                    user.getSession((err, new_session) => {
                        if (err) {
                            reject();
                        } else {
                            session = new_session;
                            resolve({session: session, user: user});
                        }
                    });
                } else {
                    resolve({session: session, user: user});
                }
            } else {
                reject();
            }
        });
    };

    const getCurrentUser = async () => {
        return await new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();
            if (user) {
                resolve(user);
            } else {
                reject();
            }
        });
    };

    const signOut = () => {
        const user = UserPool.getCurrentUser();

        if (user) {
            user.signOut();
        }
    };

    return (
        <AccountContext.Provider value={{signIn, signUp, confirmRegistration, resendConfirmationCode, forgotPassword, confirmPassword, getSession, getCurrentUser, signOut}}>
            {props.children}
        </AccountContext.Provider>
    );
};

export {Account, AccountContext};